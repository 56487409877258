<template>
  <div class="footer">
    <div class="partenaires">
      <ul>
        <li><img src="../assets/elements/part_esta.jpg" alt="" /></li>
        <li><img src="../assets/elements/part_freduc.jpg" alt="" /></li>
        <li><img src="../assets/elements/part_iutbm.jpg" alt="" /></li>
        <li><img src="../assets/elements/part_mmi.jpg" alt="" /></li>
        <li><img src="../assets/elements/part_elliadd.jpg" alt="" /></li>
      </ul>
    </div>
    <div class="infos">
      <div class="infos_wrapper">
        <ul>
          <h4>Nos partenaires</h4>
          <li><a href="https://www.esta-groupe.fr/" target="_blank">Esta Groupe</a></li>
          <li><a href="https://www.univ-fcomte.fr/structures-federatives/fr-educ" target="_blank">FR-EDUC</a></li>
          <li><a href="http://elliadd.univ-fcomte.fr/" target="_blank">Laboratoire ELLIADD</a></li>
          <li><a href="http://www.iut-bm.univ-fcomte.fr/" target="_blank">IUT BM</a></li>
          <li><a href="http://mmimontbeliard.com/" target="_blank">DUT MMI</a></li>
          <router-link to="Contact">Devenir partenaire</router-link>
        </ul>
        <ul>
          <h4>Contact</h4>
          <router-link to="Contact">Contactez-nous</router-link>
        </ul>
        <ul style="width: 33%">
          <h4>Mentions légales</h4>
			<li><router-link to="CGU">CGU</router-link></li><br>
			<li><a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
					<img alt="Licence Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
				</a><br>
				<span style="font-size: 0.7em">Cette œuvre est mise à disposition selon les termes de la <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">Licence Creative Commons Attribution - Pas d’Utilisation Commerciale 4.0 International</a></span>
			</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name:"FooterView"
}
</script>
<style lang="scss" scoped>

@import "../assets/scss/_variables.scss";

.footer {
  width: 100vw;
  @include sm {
    .partenaires {
      ul {
        li {
          img {
            width: 40px;
          }
        }
      }
    }
    .infos {
      padding: 3% 5%;
      ul {
        margin: 5px;
        font-size: 1.1em;
      }
    }
  }
  @include lg {
    .partenaires {
      ul {
        li {
          img {
            width: 80px;
          }
        }
      }
    }
    .infos {
      padding: 5% 15% 7% 15%;
      ul {
        margin: 0;
        font-size: 1.5em;
      }
    }
  }
  .partenaires {
    box-shadow: rgba($color: #000000, $alpha: 0.1) 0 -1px 15px;
    padding: 1% 0;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      li {
        display: inline-block;
      }
    }
  }
  .infos {
    background-color: #edf0f8;
    .infos_wrapper {
      margin: auto;
      display: flex;
      justify-content: space-between;
      ul {
        list-style-type: none;
        padding: 0;
        color: $secondary-color;
        h4 {
          font-family: $med-font;
        }
        a{
          color: black;
        }
      }
    }
  }
}
</style>
