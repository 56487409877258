<template>
	<div>
		<v-app-bar app>
			<v-app-bar-nav-icon @click="drawer = true" v-if="modelTel"></v-app-bar-nav-icon>
			<router-link class="nav-logo" to="/" style="color: #3d54d9" v-if="modelTel">SEO-ELP</router-link>
			<div style="display:flex; flex-grow: 1;">
				<span class="d-flex" style="justify-content: space-around; flex-grow: 1;" v-if="!modelTel">
					<router-link class="nav-logo lien" to="/">SEO-ELP</router-link>
						<router-link class="lien" to="/cours" v-if="user.role">Cours</router-link>
<!--						<router-link class="lien" to="/exercices"-->
<!--									 v-if="user.role && user.role!=='ETUDIANT'">Cas Pratiques</router-link>-->
						<router-link class="lien" to="/notes" v-if="user.role">Notes</router-link>
						<router-link class="lien" to="/classes"
									 v-if="user.role && user.role!=='ETUDIANT'">Mes classes</router-link>
						<a class="lien waves-effect waves-light btn-lg" :href="baseURL+'/admin/compte'"
						   v-if="user.role && user.role==='ADMIN'">Admin Quentin</a>
					<router-link class="lien waves-effect waves-light btn-lg" to="/admin"
						   v-if="user.role && user.role==='ADMIN'">Administration</router-link>
					<div v-if="user.role===undefined">
						<div class="connexion" @click="()=>$router.push('/connexion').catch(()=>{})">Connexion</div>
					</div>
					<div v-else style="display: flex">
						<router-link class="lien" style="font-size: 1em; margin-right: 10px" to="/home">{{ user.username }} ({{user.idUser}})</router-link>
						<div class="connexion" @click="logout">Déconnexion</div>
					</div>
				</span>
			</div>
		</v-app-bar>
		<!-- Add a navigation bar -->
		<v-navigation-drawer v-model="drawer" absolute style="position: fixed; z-index: 3">
			<v-list style="margin-top: 50px">
				<v-list-item v-if="user.role">
					<router-link class="lienDrawwer" to="/cours">Cours</router-link>
				</v-list-item>
<!--				<v-list-item v-if="user.role && user.role!=='ETUDIANT'">-->
<!--					<router-link class="lienDrawwer" to="/exercices">Cas Pratiques</router-link>-->
<!--				</v-list-item>-->
				<v-list-item v-if="user.role">
					<router-link class="lienDrawwer" to="/notes">Notes</router-link>
				</v-list-item>
				<v-list-item v-if="user.role && user.role!=='ETUDIANT'">
					<router-link class="lienDrawwer" to="/classes">Mes classes</router-link>
				</v-list-item>
				<v-list-item v-if="user.role && user.role==='ADMIN'">
					<a class="lienDrawwer" :href="baseURL+'/admin/compte'">Administration</a>
				</v-list-item>
				<br><hr><br>
				<v-list-item>
					<div v-if="user.role===undefined">
						<div class="connexion" @click="()=>$router.push('/connexion')">Connexion</div>
					</div>
					<div v-else>
						<p><router-link class="lien" style="font-size: 1em; margin-right: 10px" to="/home">{{ user.username }} ({{user.idUser}})</router-link></p>
						<a class="connexion" @click="logout">Déconnexion</a>
					</div>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import myaxios from "@/service/myaxios";
import router from "@/router";

export default {
	name: "NavBar",
	data() {
		return {
			drawer: false,
			modelTel: false,
			items: [['Cours', '/cours'], ['Cas pratiques', '/cas_pratique'], ['Notes', '/notes'], ['Mes classes', '/classes'], ['Administration', '/admin']],
			baseURL: process.env.NODE_ENV==='development'?'http://localhost:3000':''
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
	},
	methods: {
		changeRoute(index) {
			this.$router.push(this.items[index].toLowerCase());
		},
		logout() {
			myaxios.get("/api/auth/deconnexionVUE")
				.then(() => {
					this.$store.commit("setUser", {});
					this.$cookies.remove("currentUser");
					router.push("/").catch(() => {});
				})
		}
	},
	mounted() {
		this.modelTel = window.innerWidth < 850;
		window.addEventListener('resize', () => {
			this.modelTel = window.innerWidth < 850;
		})
	}
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

/* NAV BAR */

.nav-logo {
	font-family: $big-font;
	font-weight: 700;
	font-size: 1.2em;
}

.connexion {
	font-family: $big-font;
	font-weight: 500;
	background: $primary-color;
	color: white;
	padding: 10px 10px;
	border-radius: 35px;
	text-align: right;
	cursor: pointer;
}

.lien {
	height: 100%;
	transition: .4s;
	color: #3d54d9;
	padding: 10px 0;
	font-size: 1.25em;
}

.lienDrawwer {
	color: black;
}

.lien:hover {
	background-color: #3d54d9;
	color: white;
	transition: .4s;
}

</style>