<template>
	<div class="home">
		<span class="cta-btn" style="z-index: 3" @click="emitApp">
			<p>{{ contacttext }}</p>

			<img src="../assets/elements/cta.png" alt=""/>

			<div class="dropdown-menu">
				<div class="dropdown-items"></div>
			</div>
		</span>


		<div class="baseAc">
<!--			<h3 class="w-100 text-center p-4" style="background-color: #3d54d9; color:white">Site en construction, en-->
<!--				attendant vous pouvez repondre à notre enquête ci-dessous</h3>-->
<!--			<v-dialog>-->
<!--				<template v-slot:activator="{ on }" >-->
<!--					<v-btn color="primary" class="btn" dark v-on="on" style="z-index: 2">Repondre au questionnaire</v-btn>-->
<!--				</template>-->
<!--				<iframe width='800' height='800' iItemId='frameSphinx'-->
<!--						src='https://educ.sphinxonline.net/v4/s/6ppiog?c=!CfDJ8BVu5QliDsNDkjmIESY4JbUHrO0NIN8hGtPKrZeg58YZHxfCVFXZxkTW2F1Qb7cbA8NGo0qbYGLaxpOqpcxegDK4p4KY3rZb646lxQ1AnbWTzbAcGDN8RBB-vtxRQEK-PWzesLrDjH1UVHWDDiT2Yuc'-->
<!--						frameborder='0' scrolling='auto' ></iframe>-->
<!--			</v-dialog>-->
				<background />
			<div class="accueil-infos-wrapper" style="z-index: 2">
				<h1 v-scrollanimation>SEO-ELP</h1>

				<p class="slogan">
					Une formation complète et ludique pour apprendre le SEO
				</p>

<!--				<div class="btn" v-scrollanimation @click="this.scroll">-->
<!--					<p>Découvrir la formation</p>-->
<!--				</div>-->
			</div>
		</div>

		<Footer/>
	</div>
</template>

<script>
import background from "@/components/AccueilBack";
import Footer from "@/components/footer";

export default {
	name: "HomeView",
	components: {
		Footer,
		background
	},
	data() {
		return {
			contacttext: "",
			user: {
				username: "null"
			}
		};
	},
	methods: {
		emitApp() {
			this.$emit("transition", "contact");
			this.$router.push("/contact");
		},
		changeTransition() {
			this.$emit("transition", "contact");
		},
		scroll() {
			window.scrollTo({
				top: window.innerHeight,
				left: 0,
				behavior: 'smooth'
			});
		},
	}
};
</script>


<style lang="scss" scoped>

@import "../assets/scss/_variables.scss";

.home {
	width: 100vw;
}


@include sm {
	h2 {
		font-size: 1.5em;
	}
	.cta-btn {
		padding: 5px;
		height: 40px;
	}
}

@include lg {
	h2 {
		font-size: 2.7em;
	}
	.cta-btn {
		padding: 10px;
		height: 60px;
	}
}


h2 {
	text-align: center;
	font-family: $big-font;
	font-weight: 700;
}

p,
li,
a {
	font-family: $med-font;
	font-weight: 500;
}

.btn p {
	font-family: $big-font;
	font-weight: 700;
}

.cta-btn {
	background: $alert-color;
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 2%;
	right: 2%;
	border-radius: 60px;
	width: fit-content;
	transition: all 0.3s ease;
	cursor: pointer;

	img {
		height: 100%;
	}

	p {
		display: block;
		font-family: $med-font;
		font-weight: 700;
		color: white;
		font-size: 20px;
		height: fit-content;
		font-weight: 700;
		color: white;
		margin: 0 10px 0 10px;
	}
}

.fond_accueil {
	pointer-events: none;
	top: 30px;
	//z-index: -1;
}

.baseAc {
	height: 100vh;
	width: 100vw;
	font-family: $big-font;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.accueil-infos-wrapper {
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;

		h1 {
			@include vsm {
				font-size: 3em;
			}
			@include sm {
				font-size: 4em;
				span {
					font-size: 1.2em;
				}
			}
			@include md {
				font-size: 6em;
			}
			@include lg {
				font-size: 8em;
			}
			color: $primary-color;
			text-shadow: rgba($color: #000000, $alpha: 0.2) 2px 3px 6px;
			position: relative;
			margin: 0 auto;
			font-size: 8em;
			text-align: center;
			width: fit-content;
			font-family: $big-font;
			font-weight: 700;
			text-decoration: none;
			transition: all 0.7s ease;

			&.before-enter {
				opacity: 0;
				transform: translateY(50px);
			}
		}

		p.slogan {
			font-size: 3vh;
			display: block;
			color: #222222;
			padding: 0 20%;
			text-shadow: none;
			text-align: center;
			transition-delay: 0.4s;
			margin: 0 auto 5% auto;
			font-family: $big-font;
		}

		.btn {
			background: #f6f6f6;
			width: fit-content;
			margin: 0 auto;
			padding: 8px 1.5%;
			border-radius: 35px;
			box-shadow: rgba($color: $secondary-color, $alpha: 0.4) 3px 3px 10px;
			font-weight: 700;
			transition: all 0.4s ease-out;
			cursor: pointer;

			&.before-enter {
				opacity: 0;
				transform: translateY(40px);
			}

			p {
				margin: 0;
			}

			&:hover {
				background: #ffffff;
				box-shadow: rgba($color: $secondary-color, $alpha: 0.4) 6px 6px 20px;
			}
		}
	}
}


</style>
