<template>
	<div class="contact">
		<div class="form-wrapper">
			<h1>Contactez-nous</h1>

			<form v-on:submit.prevent="sendEmail()" id="formContact" style="margin-left: 20px">

				<input class="inp-text" name="sujet" type="text" placeholder="Nom" required autofocus />
				<input class="inp-text" name="email" type="text" placeholder="E-mail" required />
				<textarea class="inp-text" name="message" type="text" placeholder="Message" required />
				<v-checkbox v-model="rgpd" label=" En soumettant ce formulaire, j'accepte que les informations saisies soient utilisées dans le cadre de ma demande. Conformément au RGPD, vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en nous contactant par ce formulaire." required></v-checkbox>

				<button type="submit" value="send">Envoyer</button>
			</form>

			<div v-if="error">
				<p>{{ error }}</p>
			</div>
		</div>

		<div id="popupSign" v-if="visible" v-on:click="this.closePopup">
			<div class="popupWrapper">
				<img src="@/assets/elements/icone_check.png" alt="icone check" />
				<p>{{ messageSubmit }}</p>
			</div>
		</div>

		<aside></aside>
	</div>
</template>

<script>

import myaxios from "@/service/myaxios";

export default {
	name: "ContactView",
	data: function () {
		return {
			error: null,
			visible: false,
			messageSubmit: "",
			rgpd:false
		};
	},
	methods: {

		sendEmail() {

			this.error = null;

			let myForm = document.getElementById('formContact');
			let formData = new FormData(myForm);
			const data = {};
			for (let [key, val] of formData.entries()) {
				Object.assign(data, {[key]: val})
			}

			if (!data.email) {
				this.error = "Adresse mail manquante";
			}
			// else if (!this.validEmail(data.email)) {
			// 	this.error = "Adresse mail invalide";
			// }

			if (!this.error) {
				if (!this.rgpd)
					alert("Vous devez accepter les conditions d'utilisation pour envoyer un message.")
				else
				myaxios.post("/api/message", {nom:data.email+" : "+data.sujet,contenu:data.message})
					.then((response) => {
						console.log(response)

						this.messageSubmit = "Votre message à bien été envoyé !";
						this.visible = true;
					})
					.catch((errors) => {
						console.log(errors)

						this.messageSubmit = "Votre message n'a pas pu être envoyé, veuillez réessayer plus tard.";
						this.visible = true;
					})
			}
		},
		validEmail: function (email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		closePopup() {
			this.visible = false;
		},
	},
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/_variables.scss";

.contact {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  @include sm {
    aside {
      display: none;
    }
    .form-wrapper {
      max-width: 90vw;
    }
  }
  @include lg {
    aside {
      display: block;
    }
    .form-wrapper {
      max-width: 60%;
    }
  }
  #popupSign {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .popupWrapper {
      width: 50vh;
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);
      img {
        width: 30%;
        padding: 60px 0 20px 0;
      }
      p {
        padding: 60px 0;
        font-family: $med-font;
      }
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    h1 {
      font-size: 3em;
      font-family: $big-font;
      color: $alert-color;
      margin-bottom: 8%;
    }
    form {
      display: flex;
      width: 80%;
      flex-direction: column;
      .inp-text:focus {
        border: none;
        border-bottom: 4px solid $alert-color;
      }
      .inp-text::placeholder {
        opacity: 1;
        color: black;
      }
      .inp-text {
        outline: none;
        margin-bottom: 20px;
        border: none;
        border-bottom: 2px solid black;
        transition: all 0.1s ease;
        padding: 10px 0;
      }
      textarea.inp-text {
        resize: none;
        height: 150px;
      }
      button {
        cursor: pointer;
        margin-top: 7%;
        color: $alert-color;
        border: none;
        background: none;
        width: fit-content;
        padding: 20px 20px 20px 80px;
        text-transform: uppercase;
        font-size: 1.2em;
        font-family: $med-font;
        background-image: url("../assets/elements/buttonContact.svg");
        background-position: left;
        background-size: 55px auto;
        background-repeat: no-repeat;
      }
    }
  }
  aside {
    width: 40%;
    background: url("../assets/elements/img_contact.png");
    background-position: right;
    background-size: cover;
    height: 100vh;
  }
}
</style>
