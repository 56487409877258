import axios from 'axios'
import store from "@/store";
import router from "@/router";

const myaxios = axios.create({
	baseURL: process.env.NODE_ENV==="development"?'http://192.168.2.67:3000/':'/',
	// withCredentials: true,
});

myaxios.interceptors.request.use(
	config => {
		const token = store.state.user ? store.state.user.token : null;
		return {...config, headers: {authorization: token}}
	},
	error => {
		return Promise.reject(error)
	}
)

myaxios.interceptors.response.use(
	res => {
		//console.log("OK => ", res)
		return res
	},
	error => {
		if (error.response.status === 403 && error.response.data.err === 1) {
			store.commit("setUser", {});
			router.push("/connexion")
			alert("Votre session a expiré. Veuillez vous reconnecter.")
			return Promise.reject(error.response.data)
		} else {
			console.error("ERROR => ", error.response.status, error.response.statusText, error.response.data)
			return Promise.reject(error)
		}
	}
)


export default myaxios